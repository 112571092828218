// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "TwoColumnPodcast-module--aboutMainCircleBlurBlue--cb5e5";
export var bHeading = "TwoColumnPodcast-module--bHeading--7bfc4";
export var development = "TwoColumnPodcast-module--development--363b6";
export var gdnPromotePic = "TwoColumnPodcast-module--gdnPromotePic--879b2";
export var head = "TwoColumnPodcast-module--head--1a052";
export var heroBackgroundCircleLeft = "TwoColumnPodcast-module--heroBackgroundCircleLeft--9b987";
export var heroBackgroundCircleLeftStory = "TwoColumnPodcast-module--heroBackgroundCircleLeftStory--61ff7";
export var heroBackgroundCircleLeftStorySecond = "TwoColumnPodcast-module--heroBackgroundCircleLeftStorySecond--e1ef0";
export var heroBackgroundInnerCircleLeft = "TwoColumnPodcast-module--heroBackgroundInnerCircleLeft--bd992";
export var heroBackgroundInnerCircleLeftSecond = "TwoColumnPodcast-module--heroBackgroundInnerCircleLeftSecond--fc043";
export var knowUs = "TwoColumnPodcast-module--knowUs--54b17";
export var knowUsHeading = "TwoColumnPodcast-module--knowUsHeading--7d1be";
export var knowUsImage = "TwoColumnPodcast-module--knowUsImage--ce7ac";
export var knowUsImageDiv = "TwoColumnPodcast-module--knowUsImageDiv--6f967";
export var knowUsOurStory = "TwoColumnPodcast-module--knowUsOurStory--8d404";
export var knowUsOurStoryText = "TwoColumnPodcast-module--knowUsOurStoryText--f8cf4";
export var knowUsText = "TwoColumnPodcast-module--knowUsText--4761c";
export var ourStoryDescPara = "TwoColumnPodcast-module--ourStoryDescPara--9d0df";
export var ourStoryImg = "TwoColumnPodcast-module--ourStoryImg--142c8";
export var ourStoryImgDiv = "TwoColumnPodcast-module--ourStoryImgDiv--51b55";
export var ourStoryText = "TwoColumnPodcast-module--ourStoryText--3e1f6";
export var storyPromotePic = "TwoColumnPodcast-module--storyPromotePic--8c22f";
export var storyPromotePicSecond = "TwoColumnPodcast-module--storyPromotePicSecond--226cb";
export var topHeading = "TwoColumnPodcast-module--topHeading--719db";